// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Dialog, Button, Tooltip, Text, Textarea, PROPS, PageLoader, Price, BackLink } from 'new-ui';
import { getText } from '../../../i18n';

import { NormalCart } from '../Cart/components/NormalCart';
import { AddresserInfoItem } from '../../components/AddresserInfoItem';
import {
  ReturnPurchaseButton,
  ConsistentCartDeleteButton,
  ApproveStatusListItem,
} from '../Cart/components/ApproveStatuses/approveStatuses';
import AnalyticsBar from '../../components/AnalyticsBar';
import { BookChildDialog } from '../../components/BookChildDialog';
import { WarningBlock } from '../../components/WarningBlock';

import { APPROVETRIPRIGHT, BUYTRIPSACCOUNTRIGHT, BUYTRIPSPERSONALRIGHT } from '../../bi/constants/rights';
import ROUTES from '../../bi/constants/routes';
import { CART_STATUS, SPECIFIC_CART_TYPES, CART_ITEM_RENDER_OPTIONS } from '../../bi/constants/cart';
import { APPROVE_STATUS } from '../../bi/constants/travelApproval';
import {
  sendStatOnApprove,
  sendStatOnPurchase,
  sendStatOnDecline,
  sendStatOnOpenApprovePage,
} from '../../bi/services/cart/approveAnalytic';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import trimTimeZone from '../../bi/utils/trimTimezone';
import { NOT_AVAILABLE_FOR_DEMO } from '../../bi/constants/app';
import { getPriceAllCertificates } from '../../bi/utils/airline';
import { cartHasAirUnderageByProviders, isCartHasAirChildren, s7BonusCardWarning } from '../../bi/utils/cart';
import { isSmartAgent } from '../../bi/utils/env';
import MainAnalytic from '../../bi/utils/analytics/main';

import { StepCondition } from '../../bi/services/travelApproval/consts';
import { SERVICETYPE } from '../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { AGGREGATORS_ACCOUNT } from '../../bi/constants/accounts';

import styles from './styles/index.module.css';

const NOTIFICATIONS = {
  Declined: getText('cart:specific.notifications.declined'),
  Approved: getText('cart:specific.notifications.approved'),
  Removed: getText('cart:specific.notifications.removed'),
};

const NOTIFICATIONSTYPES = {
  DECLINED: 'Declined',
  APPROVED: 'Approved',
  REMOVED: 'Removed',
};

const BACKLINKS = {
  APPROVER: {
    LABEL: getText('cart:specific.backLinks.approver'),
    ROUTE: ROUTES.CART.APPROVE,
  },
  VIEWER: {
    LABEL: getText('cart:specific.backLinks.viewer'),
    ROUTE: ROUTES.CART.MAIN,
  },
};

const ERRORS = {
  NOCHOSENITEMSTOOLTIP: getText('cart:specific.errors.needToChoose'),
  FOULS: getText('cart:specific.errors.fouls'),
  BUY_ANY_STEP_APPROVED: getText('cart:specific.errors.buyAnyStepApproved'),
};

const LABELS = {
  SEND_REQUEST: getText('cart:specific.sendRequest'),
  PURCHASE: getText('cart:specific.purchase'),
  BOOK_TRIP: getText('cart:specific.bookTrip'),
  GO_CHECKOUT: getText('cart:specific.toCheckout'),
  UNDERAGE_REQUEST_SUCCESS: getText('cart:underage.success'),
  UNDERAGE_TOOLTIP: getText('cart:underage.tooltip'),
  COMMENT_FOR_APPROVE: getText('cart:specific.commentForApprove'),
  APPROVE: getText('cart:specific.approve'),
  APPROVE_TRIP: getText('cart:specific.approveTrip'),
  CONFIRM_ACTION: getText('cart:specific.confirmAction'),
  COMMENTS: getText('cart:specific.comments'),
  COMMENTS_FOR_DECLINE: getText('cart:specific.commentForDecline'),
  DECLINE: getText('cart:specific.decline'),
  BONUS_CARD_S7_WARNING: getText('cart:s7BonusCardWarning'),
  LOADER: getText('cart:specific.loader'),
  TRIP_NOT_FOUND: getText('cart:specific.notifications.tripNotFound'),
  APPROVAL_CKR: getText('approve:approvalsCKR'),
  TRIP_ADD_SUCCESS: (name, type) => getText('cart:specific.notifications.tripAddSuccess', { name, type }),
  TRAVEL_ASSISTANT: getText('cart:consistentCartItem.travelAssistant'),
};
@withStores([MOBX_STORES.TRANSFER_STORE])
@observer
export default class SpecificCartPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    cartService: PropTypes.object.isRequired,
    transferService: PropTypes.object.isRequired,
    workspaceService: PropTypes.object.isRequired,
    settingsService: PropTypes.object.isRequired,
    userSessionService: PropTypes.object.isRequired,
    formatService: PropTypes.object.isRequired,
    employeeService: PropTypes.object.isRequired,
    notificationService: PropTypes.object.isRequired,
    tripTagsService: PropTypes.object.isRequired,
    hotelsService: PropTypes.object.isRequired,
    sidePanelService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    accountSettingsService: PropTypes.object.isRequired,
    customAnalyticsService: PropTypes.object.isRequired,
    metricsService: PropTypes.object.isRequired,
    stores: PropTypes.shape({
      transferStore: PropTypes.object.isRequired,
    }),
    aggregationId: PropTypes.number.isRequired,
  };

  state = {
    loading: true,
    cart: {},
    type: null,
    projects: null,
    documentType: null,
    showDeclineDialog: false,
    showApproveDialog: false,
    declineComment: '',
    fouls: [],
    isUnderageReq: false,
    tripsByTripIds: [],
    showBookingChildrenModal: false,
    buttonLoading: false,
  };

  loadCart(id, isUpdate = false) {
    const { cartService } = this.props;

    if (!isUpdate) {
      this.setState({ loading: true, showDeclineDialog: false, declineComment: '' });
    }

    return cartService.getCartById(id)
      .then(cart => this.loadCartSuccess(cart, id))
      .catch(() => this.loadCartError());
  }

  loadCartSuccess = (cart, cartId) => {
    const { cartService } = this.props;
    const { tripsByTripIds } = cartService.get();

    sendStatOnOpenApprovePage(cartService, cart, cartId);

    this.updateFouls(cart);
    this.setState({ cart, loading: false, tripsByTripIds }, this.prepareTypeOfView);
  };

  loadCartError = () => {
    const { isUnderageReq } = this.state;
    const { history, notificationService } = this.props;

    if (!isUnderageReq) {
      notificationService.send({
        level: 'error',
        message: LABELS.TRIP_NOT_FOUND,
      });
    }

    history.push(ROUTES.SMARTDESK);
  };

  componentDidMount() {
    const {
      match: { params: { id } },
      customAnalyticsService,
      tripTagsService,
      airlineSeatsService,
    } = this.props;

    airlineSeatsService.getAdditionalServiceData();

    customAnalyticsService.getAnalytics();
    this.prepareCartItemsUtility(() => this.loadCart(id));
    tripTagsService.loadAccountTripTags();
  }

  prepareTypeOfView() {
    const { workspaceService: { rights: { Approve, Login } } } = this.props;
    const { cart } = this.state;

    const type = APPROVETRIPRIGHT.Available === Approve && !!cart.Approve.find(item => item.Email.toLowerCase() === Login.toLowerCase())
      ? SPECIFIC_CART_TYPES.APPROVER
      : SPECIFIC_CART_TYPES.VIEWER;

    this.setState({ type });
  }

  prepareCartItemsUtility(cb) {
    const { userSessionService } = this.props;
    const { projects, enums: { documents: documentType } } = userSessionService.get();

    this.setState({
      projects,
      documentType,
    }, () => cb());
  }

  redirectToCheckout(id) {
    const { history } = this.props;
    history.push(`${ROUTES.CART.CHECKOUTSPECIFIC}${id}`);
  }

  loadSpecificCart = () => {
    const id = this.props.match.params.id;
    this.prepareCartItemsUtility(() => this.loadCart(id, true));
  };

  handleShowBookingChildrenModal = (showBookingChildrenModal) => () => this.setState({ showBookingChildrenModal });

  handleContinueConfirm = () => {
    const { cart } = this.state;
    const hasUnderage = cartHasAirUnderageByProviders(cart.Items);

    if (hasUnderage) {
      this.handleSendRequest(cart);
    } else {
      this.handlePurchase(cart);
    }

    this.setState({ showBookingChildrenModal: false });
  };

  handlePurchase({ Id, Items }) {
    const { type, showBookingChildrenModal } = this.state;
    const hasChildren = isCartHasAirChildren(Items);

    if (hasChildren && !showBookingChildrenModal) {
      return this.handleShowBookingChildrenModal(true)();
    }

    sendStatOnPurchase(SPECIFIC_CART_TYPES, type);

    return this.redirectToCheckout(Id);
  }

  handleDeclineComment = declineComment => this.setState({ declineComment });

  handleApprove({ Id, Name }) {
    const { declineComment } = this.state;
    const { cartService } = this.props;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.APPROVE_BASKET);

    this.setState({ buttonLoading: true });

    const cartApprove = {
      CartId: Id,
      Resolution: CART_STATUS.AUTHORISED,
      Message: {
        Comment: declineComment,
      },
    };

    sendStatOnApprove(true);

    return cartService.decideCartApprove(cartApprove, this.prepareApproveId())
      .finally(() => {
        this.setState({ buttonLoading: false });
        this.handleSuccessApproveDecision({ name: Name, status: NOTIFICATIONSTYPES.APPROVED });
      });
  }

  handleDecline({ Id, Name }) {
    const { declineComment } = this.state;
    const { cartService } = this.props;
    const status = CART_STATUS.REJECTED;

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.CART.DECLINE_BASKET);

    sendStatOnDecline(declineComment);

    this.setState({ buttonLoading: true });

    cartService.decideCartApprove(
      {
        CartId: Id,
        Resolution: status,
        Message: {
          Comment: declineComment,
        },
      },
      this.prepareApproveId())
      .then(() => {
        this.setState({ buttonLoading: false });
        this.handleSuccessApproveDecision({ name: Name, status: NOTIFICATIONSTYPES.DECLINED });
      });
  }

  handleSuccessApproveDecision({ name, status }) {
    const { history } = this.props;
    this.sendNotification(name, status);
    history.push(ROUTES.CART.APPROVE);
  }

  handleRemoveCart = ({ Id, Name }, airToNote) => {
    const { cartService, history } = this.props;
    const { type } = this.state;

    cartService.removeCart(Id, airToNote).then(
      () => {
        this.sendNotification(Name, NOTIFICATIONSTYPES.REMOVED);
        history.push(type === SPECIFIC_CART_TYPES.VIEWER ? ROUTES.CART.MAIN : ROUTES.CART.APPROVE);
      },
    );
  };

  sendNotification(name = '', type) {
    const { notificationService } = this.props;

    notificationService.send({
      message: LABELS.TRIP_ADD_SUCCESS(name, NOTIFICATIONS[type]),
      level: 'success',
      qaAttr: QA_ATTRIBUTES.approve.cart.notification,
    });
  }

  prepareApproveId() {
    const { cart } = this.state;
    const { workspaceService: { rights: { Login } } } = this.props;

    const approveItem = cart.Approve.find(({ Email, Resolution }) => Email.toLowerCase() === Login.toLowerCase() && Resolution === CART_STATUS.IN_PROGRESS);

    return approveItem ? approveItem.Id : null;
  }

  setDeclineDialog = (value) => this.setState({ showDeclineDialog: value });

  setApproveDialog = (value) => this.setState({ showApproveDialog: value });

  addFoul = item => this.setState({ fouls: [...this.state.fouls, item] });

  updateFouls = (cart) => {
    const { formatService } = this.props;

    cart.Items.forEach((item) => {
      if (item.IsReserved &&
        item.ServiceType === SERVICETYPE.AIR &&
        formatService.dateObject(trimTimeZone(item.BookDeadline)).isBefore(cart.ServerTime)) {
        this.addFoul(item);
      }
    });
  };

  removeCartItem = (id) => {
    const { cartService } = this.props;

    return cartService.removeOnlyCartItem(id);
  };

  handleDeleteItem = async ({ Id }) => {
    const { cartService } = this.props;

    try {
      await cartService.removeOnlyCartItem(Id);
    } catch {}
  };

  handleUpdateCartItemsAfterRemove = async () => {
    const { cartService, history } = this.props;
    const { cart } = this.state;

    try {
      const res = await cartService.getCartById(cart.Id);

      this.setState({ cart: res }, () => {
        this.prepareTypeOfView();
        this.updateFouls(res);
      });
    } catch (e) {
      history.push(ROUTES.SMARTDESK);
    }
  };

  setAnalyticsForItem = (newCartItem, cartId) => {
    const { cart } = this.state;
    const cartItems = cart.Items.map(item => {
      if (item.Id === newCartItem.Id) return newCartItem;

      return item;
    });

    const newCart = { ...cart, Items: cartItems };
    this.loadCartSuccess(newCart, cartId);
  };

  setCustomAnalytics = async (cartId, analyticsValueId, cartItemId = null) => {
    const { cartService } = this.props;

    if (!cartItemId) {
      const cart = await cartService.setAnalyticsSpecificCart(cartId, analyticsValueId);
      this.loadCartSuccess(cart, cartId);

      return;
    }

    const cartItem = await cartService.setAnalyticsForItem(cartItemId, analyticsValueId);
    this.setAnalyticsForItem(cartItem, cartId);
  };

  unsetCustomAnalytics = async (cartId, analyticsId, cartItemId = null) => {
    const { cartService } = this.props;

    if (!cartItemId) {
      const cart = await cartService.unsetAnalyticsSpecificCart(cartId, analyticsId);
      this.loadCartSuccess(cart, cartId);

      return;
    }

    const cartItem = await cartService.unsetAnalyticsForItem(cartItemId, analyticsId);
    this.setAnalyticsForItem(cartItem, cartId);
  };

  handleSelectCompany = (cart) => {
    const cartItems = this.state.cart.Items.map(item => {
      if (item.Id !== cart.Id) return item;

      return cart;
    });

    this.setState({
      cart: {
        ...this.state.cart,
        Items: cartItems,
      },
    });
  };

  onAddAnalyticsValue = async (analyticsId, value, cartId = null, cartItemId = null) => {
    const { customAnalyticsService } = this.props;

    const analyticsValue = await customAnalyticsService.addAnalyticsValue(analyticsId, value);

    if (cartId && analyticsValue?.Id) {
      await customAnalyticsService.getAnalytics();
      await this.setCustomAnalytics(cartId, analyticsValue.Id, cartItemId);
    }
  };

  renderApproversList() {
    const { cart, type } = this.state;
    const { workspaceService: { rights: { Login: accountEmail } }, formatService, aggregationId } = this.props;
    const addresserComment = cart.Approve[0].Messages[0];
    const addresser = { name: addresserComment.Name, date: addresserComment.Date, email: addresserComment.Email };
    const approveStatusesList = [];

    cart.Approve.forEach(({ Email, Name, CreatedDate, Resolution }) => {
      if (Email.toLowerCase() !== accountEmail.toLowerCase() || (cart.Approve.length === 1 && Resolution === CART_STATUS.AUTHORISED)) {
        approveStatusesList.push({ Email, Name, CreatedDate, Resolution });
      }
    });

    const approveStatusesHtml = !!approveStatusesList.length && (
      <div className={ styles['approve-list'] }>
        {
          approveStatusesList.map(({ Email, Name, CreatedDate, Resolution }, i) => {
            const showApproversCKR = aggregationId === AGGREGATORS_ACCOUNT.CKR;
            const preparedEmail = showApproversCKR ? LABELS.APPROVAL_CKR : Email;

            return <ApproveStatusListItem
              key={ `${Email}_${i}` }
              email={ preparedEmail }
              name={ Name }
              date={ formatService.getDateDescriptor(CreatedDate).toLowerCase() }
              resolution={ Resolution }
              showApproversCKR={ showApproversCKR }
              isOffline={ cart.IsOffline }
            />;
          })
        }
      </div>
    );

    const reporterHtml = type === SPECIFIC_CART_TYPES.APPROVER && (
      <div className={ styles['addresser-info'] }>
        <AddresserInfoItem
          email={ addresser.email }
          name={ addresser.name }
          date={ formatService.getDateDescriptor(addresser.date).toLowerCase() }
          isOffline={ cart.IsOffline }
        />
      </div>
    );

    return (
      <div>
        { reporterHtml }
        { approveStatusesHtml }
      </div>
    );
  }

  preparePurchaseButtonProps = (hasUnderage) => {
    const { cart } = this.state;
    const {
      aggregationId,
      workspaceService: {
        rights,
      },
      accountSettingsService: {
        store: {
          accountSettings: {
            buyTripAnyStep,
          },
        },
      },
    } = this.props;

    const statusRights = rights.BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unlimited;
    const buyTripStep = buyTripAnyStep && rights.Approve === APPROVETRIPRIGHT.Available;
    const cKRRestrictions = aggregationId === AGGREGATORS_ACCOUNT.CKR;
    let label = LABELS.GO_CHECKOUT;

    if (hasUnderage) {
      label = LABELS.SEND_REQUEST;
    }

    if (statusRights || buyTripStep) {
      label = LABELS.PURCHASE;
    }

    if (cKRRestrictions) {
      label = LABELS.BOOK_TRIP;
    }

    return {
      label,
      onClick: hasUnderage ? () => this.handleSendRequest(cart) : () => this.handlePurchase(cart),
    };
  };

  handleSendRequest = () => {
    const { cartService, notificationService } = this.props;
    const { cart: { Id, Items }, showBookingChildrenModal } = this.state;

    const hasChildren = isCartHasAirChildren(Items);

    if (hasChildren && !showBookingChildrenModal) {
      this.handleShowBookingChildrenModal(true)();

      return;
    }

    this.setState({ loading: true });

    cartService.justSendUnderageCart(Id)
      .then(() => {
        this.setState({ isUnderageReq: true }, () => {
          this.loadCart(Id);
        });

        notificationService.send({
          level: 'success',
          message: LABELS.UNDERAGE_REQUEST_SUCCESS,
        });
      });
  };

  potentialBuyToTrip = () => {
    const { cart, type } = this.state;
    const {
      workspaceService: {
        rights: {
          BuyTripAccount,
          BuyTripPersonal,
          BuyInsurance,
          Approve: approveTrain,
        },
      },
      accountSettingsService: {
        store: {
          accountSettings: {
            buyTripAnyStep,
          },
        },
      },
    } = this.props;
    const { Approve, ShowPurchaseButton, Items } = cart;
    const isApproveByScheme = Approve.some(({ ApprovalStepId }) => !!ApprovalStepId);
    const isApprovalCondition = Approve.some(({ ApprovalCondition, Resolution }) =>
      (ApprovalCondition === StepCondition.AllApprovers) && (Resolution === APPROVE_STATUS.WAITING_APPROVE),
    );
    const isIncludeInsurance = Items.some(({ ServiceType }) => ServiceType === SERVICETYPE.INSURANCE);

    const rulesBuyTripAnyStep = approveTrain === APPROVETRIPRIGHT.Available
      && BuyTripPersonal === BUYTRIPSPERSONALRIGHT.Unlimited
      && BuyTripAccount === BUYTRIPSPERSONALRIGHT.Unlimited;

    const buyTrip = buyTripAnyStep && rulesBuyTripAnyStep;
    const showButtonType = ShowPurchaseButton && type === SPECIFIC_CART_TYPES.APPROVER;

    const canPurchase = isApproveByScheme
      ? (buyTrip || showButtonType) && (BuyInsurance || !isIncludeInsurance)
      : BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unlimited;

    const registerApprove = cart.Approve.filter(({ Resolution }) => Resolution === APPROVE_STATUS.APPROVED);
    const conditionOfApprove = isApprovalCondition && !(cart.Approve.length - 1 === registerApprove.length);
    const buyTripAnyStepNoApproved = buyTrip && conditionOfApprove;

    return {
      canPurchase,
      buyTripAnyStepNoApproved,
    };
  };

  renderTextTooltip = (text) => (
    <Text
      color='white'
      type='NORMAL_14_130'
    >
      { text }
    </Text>
  );

  renderTooltip = ({ haveFouls }, hasUnderage, isDemo, buyAnyStepNoApproved) => {
    const fouls = haveFouls && this.renderTextTooltip(ERRORS.FOULS);
    const underage = hasUnderage && this.renderTextTooltip(LABELS.UNDERAGE_TOOLTIP);
    const demo = isDemo && this.renderTextTooltip(NOT_AVAILABLE_FOR_DEMO);
    const buyAnyStepApproved = buyAnyStepNoApproved && this.renderTextTooltip(ERRORS.BUY_ANY_STEP_APPROVED);

    return (fouls || underage || demo || buyAnyStepApproved) && (
      <>
        { buyAnyStepApproved }
        { demo }
        { fouls }
        { underage }
      </>
    );
  };

  renderTripPurchaseButton = () => {
    const { cart, fouls, loading } = this.state;
    const {
      workspaceService: {
        isDemo,
      },
      stores: {
        transferStore: {
          validationTransferFields,
        },
      },
    } = this.props;

    if (cart.IsOffline) return null;

    const { buyTripAnyStepNoApproved } = this.potentialBuyToTrip();

    const hasUnderage = cartHasAirUnderageByProviders(cart.Items);
    const haveFouls = !!fouls && !!fouls.length && fouls.length > 0;

    const { label, onClick } = this.preparePurchaseButtonProps(hasUnderage);

    const className = [styles['tooltip-wrapper']];

    if (buyTripAnyStepNoApproved) {
      className.push(styles['tooltip-width']);
    }

    return (
      <Tooltip
        renderContent={ () => (
          <div className={ className.join(' ') }>
            { this.renderTooltip({ haveFouls }, hasUnderage, isDemo, buyTripAnyStepNoApproved) }
          </div>
        ) }
        show={ buyTripAnyStepNoApproved || haveFouls || hasUnderage || isDemo }
      >
        <Button
          onClick={ onClick }
          type={ PROPS.BUTTON.TYPES.SECONDARY }
          disabled={ buyTripAnyStepNoApproved || haveFouls || isDemo || validationTransferFields }
          loading={ loading }
          qaAttr={ QA_ATTRIBUTES.approve.cart.buttonPurchase }
        >
          { label }
        </Button>
      </Tooltip>
    );
  };

  renderTripApproveButton() {
    const { workspaceService: { isDemo } } = this.props;

    return (
      <Tooltip
        className={ styles.item }
        renderContent={ () => (
          <div className={ styles['tooltip-wrapper'] }>
            { this.renderTooltip({ haveFouls: false }, false, isDemo) }
          </div>
        ) }
        show={ isDemo }
      >
        <Button
          disabled={ isDemo }
          type={ PROPS.BUTTON.TYPES.PRIMARY }
          onClick={ () => this.setApproveDialog(true) }
          qaAttr={ QA_ATTRIBUTES.approve.cart.buttonApprove }
        >
          { LABELS.APPROVE }
        </Button>
      </Tooltip>
    );
  }

  renderTripDeclineButton = () => {
    const { workspaceService: { isDemo } } = this.props;

    return (
      <Tooltip
        className={ styles.item }
        renderContent={ () => (
          <div className={ styles['tooltip-wrapper'] }>
            { this.renderTooltip({ haveFouls: false }, false, isDemo) }
          </div>
        ) }
        show={ isDemo }
      >
        <Button
          disabled={ isDemo }
          onClick={ () => this.setDeclineDialog(true) }
          type={ PROPS.BUTTON.TYPES.TEXTUAL }
          className={ styles.decline }
        >
          { LABELS.DECLINE }
        </Button>
      </Tooltip>
    );
  };

  renderApproverCartActions() {
    const { cart } = this.state;
    const { canPurchase } = this.potentialBuyToTrip();

    if (!cart) {
      return null;
    }

    return (
      <>
        { canPurchase && this.renderTripPurchaseButton() }
        { this.renderTripApproveButton() }
        { this.renderTripDeclineButton() }
      </>
    );
  }

  renderDefaultCartActions = () => {
    const { cart, fouls } = this.state;
    const haveFouls = !!fouls && !!fouls.length && fouls.length > 0;

    if (cart.Status === CART_STATUS.AUTHORISED) {
      return this.renderTripPurchaseButton();
    }

    return <ReturnPurchaseButton
      type={ cart.Status }
      item={ cart }
      onPurchase={ item => this.handlePurchase(item) }
      haveFouls={ haveFouls }
      hasUnderage={ false }
    />;
  };

  renderApproveDialog = () => {
    const { showApproveDialog, cart, declineComment, buttonLoading } = this.state;

    return (
      <Dialog
        showClosing
        show={ showApproveDialog }
        onChange={ this.setApproveDialog }
        qaAttrWrapper={ QA_ATTRIBUTES.approve.cart.dialog.window }
      >
        <div className={ styles['dialog-content'] }>
          <Text type={ PROPS.TEXT.TYPES.BOLD_20 } className={ styles['dialog-header'] }>{ LABELS.CONFIRM_ACTION }</Text>
          <Textarea
            placeholder={ LABELS.COMMENT_FOR_APPROVE }
            value={ declineComment }
            onChange={ this.handleDeclineComment }
          />
          <div className={ styles['dialog-actions'] }>
            <Button
              type={ PROPS.BUTTON.TYPES.PRIMARY }
              onClick={ () => this.handleApprove(cart) }
              loading={ buttonLoading }
              qaAttr={ QA_ATTRIBUTES.approve.cart.dialog.buttonConfirm }
            >
              { LABELS.APPROVE_TRIP }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  renderDeclineDialog = () => {
    const { showDeclineDialog, declineComment, cart, buttonLoading } = this.state;

    return (
      <Dialog show={ showDeclineDialog } onChange={ this.setDeclineDialog } showClosing>
        <div className={ styles['dialog-content'] }>
          <Text type={ PROPS.TEXT.TYPES.BOLD_20 } className={ styles['dialog-header'] }>{ LABELS.CONFIRM_ACTION }</Text>
          <Textarea
            placeholder={ LABELS.COMMENTS_FOR_DECLINE }
            value={ declineComment }
            onChange={ (value) => this.setState({ declineComment: value }) }
          />
          <div className={ styles['dialog-actions'] }>
            <Button
              type={ PROPS.BUTTON.TYPES.PRIMARY }
              onClick={ () => this.handleDecline(cart) }
              loading={ buttonLoading }
            >
              {LABELS.DECLINE}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  renderPrice() {
    const { cart } = this.state;

    let price = 0;

    cart.Items.forEach((item) => { price += item.Price; });

    return (
      <Price marginSmall value={ getPriceAllCertificates(cart.Items) + price } type={ PROPS.TEXT.TYPES.BOLD_24 } />
    );
  }

  renderActions() {
    const { type } = this.state;

    const canApprove = type === SPECIFIC_CART_TYPES.APPROVER;
    const cartActionsButtonHtml = canApprove ?
      this.renderApproverCartActions() :
      this.renderDefaultCartActions();

    return (
      <div className={ styles.actions }>
        { this.renderPrice() }
        <div className={ styles.buttons }>
          { cartActionsButtonHtml }
        </div>
      </div>
    );
  }

  renderComments() {
    const { cart: { Approve: approve, IsOffline } } = this.state;
    const { formatService } = this.props;
    const comments = [];

    // Если оставил коммент при отправке, показываем в списке
    if (approve[0].Messages[0].Comment) {
      comments.push(approve[0].Messages[0]);
    }

    const getNameComment = (name, index) => (IsOffline && index === 0 ? LABELS.TRAVEL_ASSISTANT : name);

    approve.forEach(({ Messages }) => {
      const messages = [...Messages];
      messages.splice(0, 1);
      comments.push(...messages.filter(message => !!message.Comment));
    });

    const blockHtml = !!comments.length && (
      <div className={ styles['comments-list'] }>
        <Text type={ PROPS.TEXT.TYPES.BOLD_24 } className={ styles.header }>{ LABELS.COMMENTS }</Text>
        {
          comments.map(({ Email, Name, Comment, Date }, index) => (
            <div key={ `${Email}${Date}${index}` } className={ styles.item }>
              <Text>{ formatService.getDateDescriptor(Date) }</Text>
              &nbsp;
              <Tooltip renderContent={ () => (
                <div className={ styles['tooltip-container'] }>
                  <Text color={ PROPS.TEXT.COLORS.WHITE } type={ PROPS.TEXT.TYPES.NORMAL_14_130 }>{ getNameComment(Email, index) }</Text>
                </div>
              ) }
              >
                <Text color={ PROPS.TEXT.COLORS.ACCENT } className={ styles.name }>{ getNameComment(Name, index) }</Text>
              </Tooltip>
              <Text type={ PROPS.TEXT.TYPES.SEMIBOLD_16 }>: { Comment }</Text>
            </div>
          ))
        }
      </div>
    );

    return blockHtml;
  }

  renderDeleteAction() {
    const { type, cart } = this.state;
    const { workspaceService: { isDemo }, aggregationId } = this.props;

    const cKRRestrictions = aggregationId === AGGREGATORS_ACCOUNT.CKR;

    return !cKRRestrictions ? (
      <div className={ styles.delete }>
        <ConsistentCartDeleteButton
          item={ cart }
          type={ cart.Status }
          onDelete={ this.handleRemoveCart }
          mode={ type }
          isDemo={ isDemo }
        />
      </div>
    ) : null;
  }

  renderBookChildrenDialog = () => (
    <BookChildDialog
      show={ this.state.showBookingChildrenModal }
      onClose={ this.handleShowBookingChildrenModal(false) }
      onContinue={ this.handleContinueConfirm }
    />
  );

  renderItems = () => {
    const {
      cartService,
      transferService,
      workspaceService,
      settingsService,
      formatService,
      airlineSeatsStore,
      airlineBaggageStore,
      employeeService,
      tripTagsService,
      hotelsService,
      sidePanelService,
      history,
      userSessionService,
      featureFlagsService,
      accountSettingsService,
      metricsService,
      customAnalyticsService: {
        store: { sortedCustomAnalytics },
      },
      aggregationId,
      trainsService,
    } = this.props;
    const { cart: { ServerTime, Items, Id, Status }, documentType, projects, type, tripsByTripIds } = this.state;
    const serverTime = formatService.dateObject(ServerTime);
    const showDeleteBtnCondition = Items.length > 1 &&
      (type === SPECIFIC_CART_TYPES.APPROVER || (type === SPECIFIC_CART_TYPES.VIEWER && Status === CART_STATUS.AUTHORISED));

    const analytics = sortedCustomAnalytics.filter(({ ApplyToTrip }) => !ApplyToTrip);

    const warningBlockHtml = s7BonusCardWarning(Items) && <WarningBlock
      text={ LABELS.BONUS_CARD_S7_WARNING }
      type='SEMIBOLD_16'
      color='red'
    />;

    const itemsHtml = Items.map((item, index) => (
      <div key={ `cart_${item.Id}_${index}` }>
        <NormalCart
          cartId={ Id }
          index={ index }
          tripsByTripIds={ tripsByTripIds }
          aggregationId={ aggregationId }
          airlineSeatsStore={ airlineSeatsStore }
          airlineBaggageStore={ airlineBaggageStore }
          renderOptions={ CART_ITEM_RENDER_OPTIONS }
          hotelsService={ hotelsService }
          trainsService={ trainsService }
          metricsService={ metricsService }
          item={ item }
          documentType={ documentType }
          projects={ projects }
          employeeService={ employeeService }
          cartService={ cartService }
          transferService={ transferService }
          workspaceService={ workspaceService }
          settingsService={ settingsService }
          tripTagsService={ tripTagsService }
          featureFlagsService={ featureFlagsService }
          accountSettingsService={ accountSettingsService }
          history={ history }
          onMoveToNotepad={ () => { } }
          onDeleteItem={ () => this.handleDeleteItem(item, Id) }
          onUpdateCartItemsAfterRemove={ this.handleUpdateCartItemsAfterRemove }
          onTimeIsUp={ () => this.addFoul(item) }
          serverTime={ serverTime }
          allowedEmployees={ item.Employees }
          readonly
          showDeleteBtn={ showDeleteBtnCondition }
          showFindReplacementBtn={ false }
          confirmDelete
          specificCartType={ type }
          sidePanelService={ sidePanelService }
          userSessionService={ userSessionService }
          onLoadSpecificCart={ this.loadSpecificCart }
          customAnalytics={ analytics }
          setCustomAnalytics={ this.setCustomAnalytics }
          unsetCustomAnalytics={ this.unsetCustomAnalytics }
          onAddAnalyticsValue={ this.onAddAnalyticsValue }
          onSelectCompany={ this.handleSelectCompany }
          isSpecificCart
          normalCartLength={ Items.length }
        />
      </div>
    ));

    return (
      <div>
        { warningBlockHtml }
        { itemsHtml }
      </div>
    );
  };

  renderLoading = () => (
    <PageLoader text={ LABELS.LOADER } />
  );

  renderBackLink = () => {
    const { type } = this.state;

    return type && (
      <BackLink
        className={ styles.back }
        link={ BACKLINKS[type].ROUTE }
        text={ BACKLINKS[type].LABEL }
        alternativeDesign={ isSmartAgent }
      />
    );
  };

  renderHeader = () => {
    const { cart } = this.state;

    return cart.Name && (
      <Text
        className={ styles.header }
        type={ PROPS.TEXT.TYPES.BOLD_32 }
        qaAttr={ QA_ATTRIBUTES.approve.cart.header }
      >
        { cart.Name }
      </Text>
    );
  };

  renderCustomAnalytics = () => {
    const {
      customAnalyticsService: {
        store: { sortedCustomAnalytics },
      },
    } = this.props;
    const { cart: { UserAnalytics: userAnalytics, Id: cartId } } = this.state;

    const analyticsWhichAppliesToTrip = sortedCustomAnalytics.filter(({ ApplyToTrip }) => ApplyToTrip);

    return (
      <AnalyticsBar
        withRequiredValidation
        cartId={ cartId }
        analyticsList={ analyticsWhichAppliesToTrip }
        userAnalytics={ userAnalytics }
        onSet={ this.setCustomAnalytics }
        onUnset={ this.unsetCustomAnalytics }
        onAddAnalyticsValue={ (aId, value) => this.onAddAnalyticsValue(aId, value, cartId) }
      />
    );
  };

  render() {
    const {
      state: { loading },
      props: {
        customAnalyticsService: {
          store: { loading: analyticsLoading },
        },
      },
    } = this;

    if (loading || analyticsLoading) {
      return this.renderLoading();
    }

    return (
      <div className={ styles.content }>
        { this.renderBackLink() }
        { this.renderHeader() }
        { this.renderApproversList() }
        { this.renderComments() }
        { this.renderDeleteAction() }
        { this.renderCustomAnalytics() }
        { this.renderItems() }
        { this.renderActions() }
        { this.renderApproveDialog() }
        { this.renderDeclineDialog() }
        { this.renderBookChildrenDialog()}
      </div>
    );
  }
}
