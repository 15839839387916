import React, { useState } from 'react';

import { Datepicker, Input, LinkButton, Text } from 'new-ui';
import { Moment } from 'moment';
import { getText } from '../../../../../../../i18n';

import { FieldLabel } from '../../../../../../components/FieldLabel';
import { FormWrapper } from '../../../../../../components/FormWrapper';

import DOCUMENTSFIELDS from '../../../../../../bi/constants/documentsFields';
import { IDS_FOR_INTEGRATION_TESTS } from '../../../../../../bi/constants/idsForInregrationTests';
import { QA_ATTRIBUTES } from '../../../../../../bi/constants/attributesForTests';

import { getMoment, momentObject } from '../../../../../../bi/utils/formatDate';
import { checkUaWithDomesticPassport } from '../../../../../../bi/utils/documents';
import { onlyNumbersOrEmpty } from '../../../../../../bi/utils/text';

import styles from '../index.module.css';

const DATEPICKER_PLACEHOLDER = '__.__.____';

const LABELS = {
  NAME: getText('settings:employees.employee.steps.documents.name'),
  LAST_NAME: getText('settings:employees.employee.steps.documents.surname'),
  PATRONYMIC: getText('settings:employees.employee.steps.documents.patronymic'),
  LATIN_LASTNAME: getText('settings:employees.employee.steps.documents.ru.lastName'),
  LATIN_FIRSTNAME: getText('settings:employees.employee.steps.documents.ru.firstName'),
  LATIN_MIDDLENAME: getText('settings:employees.employee.steps.documents.ru.latinPatronymic'),
  DOCUMENT_NUMBER: getText('settings:employees.employee.steps.documents.domesticOrForeign.number'),
  DOCUMENT_DUE_DATE: getText('settings:employees.employee.steps.documents.domesticOrForeign.dueDate'),
  EDIT_TRANSLIT_TITLE: getText('settings:employees.employee.steps.documents.ru.translit.title'),
  CHECK_TRANLITIRATION: getText('settings:employees.employee.steps.documents.ru.translit.checkTransliteration'),
  SHOW_EDIT_TRANSLIT: getText('settings:employees.employee.steps.documents.ru.translit.show'),
  HIDE_EDIT_TRANSLIT: getText('settings:employees.employee.steps.documents.ru.translit.edit'),
};

const { employee: { documents: { passport, passportRu } } } = QA_ATTRIBUTES;

interface IDomesticOrForeignCyrillicDocument {
  error: {
    Name: string,
    Surname: string,
    Number: string,
    FirstName: string,
    LastName: string,
    Patronymic: string,
    MiddleName: string,
  },
  document: {
    Surname: string,
    Name: string,
    Number: string,
    DueDate: string,
    Type: string,
    LastName: string,
    FirstName: string,
    Patronymic: string,
    MiddleName: string,
    NoPatronymic: boolean,
  },
  employeeCode: string,
  onResetErrors(field: string): void,
  onValidationInput(field: string): void,
  onInputChange(value: string, field: string): void,
  onNumberChange(value: string): void,
  onDateChange(field: string, value: string | Moment | null): void,
  onBlurInput(field: string, value: string): void,
  isDomestic: boolean,
  index: number,
  showTransliterate: boolean,
  disabled: boolean,
  disabledFieldsNamesChangeAirTrip: boolean,
  renderCheckboxNoPatronymic: (value: boolean, type: string) => React.ReactNode;
}

const DomesticOrForeignCyrillicDocument = ({
  error: {
    Name: errName,
    Surname: errSurname,
    Number: errNumber,
    FirstName: errFirstName,
    LastName: errLastName,
    Patronymic: errPatronymic,
    MiddleName: errMiddleName,
  },
  document: {
    Surname,
    Name,
    Number,
    DueDate,
    Type,
    LastName,
    FirstName,
    Patronymic,
    MiddleName,
    NoPatronymic,
  },
  employeeCode,
  onResetErrors,
  onValidationInput,
  onInputChange,
  onNumberChange,
  onDateChange,
  onBlurInput,
  isDomestic,
  index,
  showTransliterate,
  disabled,
  disabledFieldsNamesChangeAirTrip,
  renderCheckboxNoPatronymic,
}: IDomesticOrForeignCyrillicDocument) => {
  const isUaWithDomesticPassport = checkUaWithDomesticPassport(employeeCode, Type);
  const [showForm, setShowForm] = useState(false);

  const mustHaveCondition = !!Surname.trim().length || !!Name.trim().length || !!Number.trim().length || !!DueDate || !!Patronymic.length;
  const mustHaveDueDate = mustHaveCondition && !isUaWithDomesticPassport;

  const validateNumberFn = isUaWithDomesticPassport
    ? () => onBlurInput(Number, DOCUMENTSFIELDS.NUMBER)
    : () => onValidationInput(DOCUMENTSFIELDS.DUEDATE);

  const ids = isDomestic ? IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.DOMESTIC : IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.FOREIGN;

  const preparedDueDate = DueDate ? momentObject(DueDate) : '';

  const formHtml = showForm && (
    <div className={ styles.row }>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.LATIN_LASTNAME } mustHave />
        <Input
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.TRANSLITED_SURNAME }
          value={ LastName }
          onFocus={ () => onResetErrors(DOCUMENTSFIELDS.LASTNAME) }
          onBlur={ () => onBlurInput(LastName, DOCUMENTSFIELDS.LASTNAME) }
          onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.LASTNAME) }
          error={ errLastName }
          disabled={ disabled }
          qaAttr={ passportRu.translited.lastname }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.LATIN_FIRSTNAME } mustHave />
        <Input
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.TRANSILTED_NAME }
          value={ FirstName }
          onFocus={ () => onResetErrors(DOCUMENTSFIELDS.FIRSTNAME) }
          onBlur={ () => onBlurInput(FirstName, DOCUMENTSFIELDS.FIRSTNAME) }
          onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.FIRSTNAME) }
          error={ errFirstName }
          disabled={ disabled }
          qaAttr={ passportRu.translited.firstname }
        />
      </div>
      <div className={ styles.item }>
        <FieldLabel text={ LABELS.LATIN_MIDDLENAME } mustHave />
        <Input
          id={ IDS_FOR_INTEGRATION_TESTS.EMPLOYEE.DOCUMENTS.RU.TRANSLITED_PATRONYMIC }
          value={ MiddleName }
          onFocus={ () => onResetErrors(DOCUMENTSFIELDS.MIDDLENAME) }
          onBlur={ () => onBlurInput(MiddleName, DOCUMENTSFIELDS.MIDDLENAME) }
          onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.MIDDLENAME) }
          error={ errMiddleName }
          disabled={ disabled }
          qaAttr={ passportRu.translited.patronymic }
        />
      </div>
    </div>
  );

  const editTitle = showForm ? LABELS.HIDE_EDIT_TRANSLIT : LABELS.SHOW_EDIT_TRANSLIT;

  const textHtml = showTransliterate && (
    <>
      <Text
        className={ styles.translit }
        type='NORMAL_14_130'
      >
        {LABELS.EDIT_TRANSLIT_TITLE}
        {' '}
        <b data-qa={ passportRu.showTranslitedFullName }>{LastName} {FirstName} {MiddleName} </b>
        {' '}
        <LinkButton
          qaAttr={ passportRu.showHideTranslited }
          tabIndex={ -1 }
          onClick={ () => setShowForm(!showForm) }
        >({editTitle}).</LinkButton>
        {' '}
        {LABELS.CHECK_TRANLITIRATION}
      </Text>
      {formHtml}
    </>
  );

  const wrapperClassName = disabled ? styles.disabled : '';
  const disabledItemClassName = !disabled && disabledFieldsNamesChangeAirTrip ? styles.disabled : '';

  return (
    <FormWrapper className={ wrapperClassName }>
      <div className={ styles.row }>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.LAST_NAME } mustHave={ mustHaveCondition } />
          <Input
            id={ ids.SURNAME }
            value={ Surname }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.SURNAME) }
            onBlur={ () => onBlurInput(Surname, DOCUMENTSFIELDS.SURNAME) }
            onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.SURNAME) }
            error={ errSurname }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ `${passport}-${ids.SURNAME}-${index}` }
          />
        </div>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.NAME } mustHave={ mustHaveCondition } />
          <Input
            id={ ids.NAME }
            value={ Name }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.NAME) }
            onBlur={ () => onBlurInput(Name, DOCUMENTSFIELDS.NAME) }
            onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.NAME) }
            error={ errName }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ `${passport}-${ids.NAME}-${index}` }
          />
        </div>
        <div className={ `${styles.item} ${disabledItemClassName}` }>
          <FieldLabel text={ LABELS.PATRONYMIC } mustHave={ mustHaveCondition } />
          <Input
            id={ `${ids.PATRONOMYC}` }
            value={ Patronymic }
            onFocus={ () => onResetErrors(DOCUMENTSFIELDS.PATRONYMIC) }
            onBlur={ () => onBlurInput(Patronymic, DOCUMENTSFIELDS.PATRONYMIC) }
            onChange={ (value) => onInputChange(value, DOCUMENTSFIELDS.PATRONYMIC) }
            error={ errPatronymic }
            disabled={ disabledFieldsNamesChangeAirTrip }
            qaAttr={ `${passport}-${ids.PATRONOMYC}-${index}` }
          />
        </div>
      </div>
      {textHtml}
      <div className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.DOCUMENT_NUMBER } mustHave={ mustHaveCondition } />
          <Input
            id={ ids.NUMBER }
            value={ Number }
            onChange={ value => onNumberChange(value) }
            withValidation
            validateFn={ v => (!isDomestic ? v.length > 9 || !onlyNumbersOrEmpty(v) : false) }
            onBlur={ validateNumberFn }
            debounceMs={ 0 }
            error={ errNumber }
            disabled={ disabled }
            qaAttr={ `${passport}-${ids.NUMBER}-${index}` }
          />
        </div>
        <div className={ styles.item }>
          <FieldLabel text={ LABELS.DOCUMENT_DUE_DATE } mustHave={ mustHaveDueDate } />
          <Datepicker
            closeOnTabOut
            id={ ids.DUE_DATE }
            inputClassName={ styles.datepicker }
            value={ preparedDueDate }
            min={ getMoment().startOf('d') }
            onChange={ value => onDateChange(DOCUMENTSFIELDS.DUEDATE, value) }
            placeholder={ DATEPICKER_PLACEHOLDER }
            disabled={ disabled }
            qaAttr={ `${passport}-${ids.DUE_DATE}-${index}` }
          />
        </div>
        { renderCheckboxNoPatronymic(NoPatronymic, Type) }
      </div>
    </FormWrapper>
  );
};

export { DomesticOrForeignCyrillicDocument };
