import React from 'react';

import SERVICETYPE from '../../../../../bi/constants/serviceType';

import { ISourcesItem } from '../../../../../bi/services/checkout/types';

import styles from '../style.module.css';

interface ITransferViewerProps {
  item: ISourcesItem
  itemData: any;
}

export const TransferViewers = ({
  item,
  itemData,
}: ITransferViewerProps) => {
  const { ServiceType } = item;

  if (ServiceType !== SERVICETYPE.TRANSFER) return null;

  const { Viewers } = itemData;

  if (!Viewers) return null;

  const viewersListHtml = Viewers.map((number: number, idx: number) => (
    <div key={ `${styles.viewer}_${idx}` } className={ styles.viewer }>
      {number}
    </div>
  ));

  return (
    <div className={ styles.viewers }>
      { viewersListHtml }
    </div>
  );
};
