import Api from '../../api';

import { airlineBaggageStore, IAirlineBaggageStore } from './store';

import { omit } from '../../utils/common';
import { createUnselectedBaggageWarning } from './utils/createUnselectedBaggageWarning';
import { IUnderageEmployeesItem } from '../../utils/employees';

import { AirRoute } from '../../types/airline';
import { IBaggage, IBaggageItemButton, ISelectedBaggage } from '../../types/airlineAdditionalServicesBaggage';

class AirlineBaggageService {
  api: Api['airlineBaggage'];
  store: IAirlineBaggageStore;

  constructor(api: Api) {
    this.api = api.airlineBaggage;
    this.store = airlineBaggageStore;
  }

  getBaggage = async (providerName: string, offerId: string, ticketId: number) => {
    try {
      const baggage = await this.api.getBaggageOffers(providerName, offerId);
      this.store.setBaggageOffers(baggage, ticketId);
    } catch (error) {
      this.store.setBaggageOffers(null, ticketId);
      this.store.setBaggageOffersGetError(String(ticketId));
    }
  };

  setEmployeeList = (employeeList: IUnderageEmployeesItem[], currentItemId: number) => {
    this.store.setEmployeeList({ ...this.store.employeeList, [currentItemId]: employeeList });
  };

  updateSelectedBaggage = (
    ticketId: number,
    employeeId: number,
    routeId: string,
    baggageId: number,
    baggagePrice: number,
    routeIndex: number,
    routes: AirRoute[],
  ) => {
    const baggageButtonKey = `${ticketId}_${employeeId}_${routeId}_${routeIndex}`;

    this.store.setRoutes({
      ...this.store.routes,
      [baggageButtonKey]: {
        id: baggageId,
        price: baggagePrice,
      },
    });

    const baggage = {
      OfferId: baggageId,
      TravellerId: employeeId,
      routeId,
    };

    this.updateBaggageIds(baggage, ticketId);
    this.updateTotalBaggageCost(ticketId);
    this.setSelectedBaggage(ticketId, routes);
  };

  updateBaggageIds = (baggage: IBaggageItemButton, ticketId: number) => {
    this.store.setBaggageIds(baggage, ticketId);
  };

  deleteBaggageOffer = (ticketId: number) => {
    this.store.selectedBaggage = { ...omit(this.store.selectedBaggage, [`${ticketId}`]) };
    this.store.baggageOffers = { ...omit(this.store.baggageOffers, [`${ticketId}`]) };
  };

  deleteBaggageOffers = () => {
    this.store.selectedBaggage = {} as Record<string, ISelectedBaggage>;
    this.store.baggageOffers = {} as Record<string, IBaggage>;
  };

  getBaggagesForSave = (ticketId: number, ID: string | number) => this.store.baggageIds[ticketId]
    .filter(({ routeId }) => routeId === ID)
    .map(({ OfferId, TravellerId }) => {
      if (typeof OfferId !== 'number') {
        return null;
      }

      return { OfferId, TravellerId };
    })
    .filter(Boolean);

  getBaggageRoutesForSave = (routes: AirRoute[], ticketId: number) => routes?.map(({ ID, Segments }) => {
    const firstSegment = Segments[0];
    const lastSegment = Segments[Segments.length - 1];

    const Arrival = lastSegment.ArrivalCityCode;
    const Departure = firstSegment.DepartureCityCode;

    return ({
      Arrival,
      Departure,
      Baggages: this.getBaggagesForSave(ticketId, ID),
    });
  });

  setSelectedBaggage = (ticketId: number, routes: AirRoute[]) => {
    if (!this.store.baggageOffers) {
      return;
    }

    const selectedBaggage = {
      ...omit(this.store.baggageOffers[ticketId], ['Error']),
      BaggageRoutes: this.getBaggageRoutesForSave(routes, ticketId),
    };

    this.store.setSelectedBaggage(selectedBaggage, ticketId);

    const warnings = createUnselectedBaggageWarning(
      this.store.selectedBaggage,
      this.store.employeeList[ticketId],
      routes,
    );

    this.store.setUnselectedBaggageWarning(warnings[ticketId] || [], ticketId);
  };

  setRoutes = (route: Record<string, Record<string, number>>) => {
    this.store.setRoutes(route);
  };

  clearRoutes = (ticketId: number) => {
    this.store.routes = {
      ...Object.keys(this.store.routes).reduce((routes, key) => {
        const resultRoutes = { ...routes };

        if (!key.startsWith(String(ticketId))) {
          resultRoutes[key] = this.store.routes[key];
        }

        return resultRoutes;
      }, {} as Record<string, Record<string, number>>),
    };
  };

  clearBaggages = (ticketId: number) => {
    const savedPriceItemId = this.store.baggageOffers?.[ticketId]?.SavedPriceItemId || '';

    const initialSelectedBaggage = {
      SavedPriceItemId: savedPriceItemId,
      BaggageRoutes: [],
    };

    this.clearRoutes(ticketId);
    this.store.clearBaggageIds(ticketId);
    this.store.clearUnselectedBaggageWarning(ticketId);
    this.store.setSelectedBaggage(initialSelectedBaggage, ticketId);
    this.store.setTotalBaggageCost(0, ticketId);
    this.store.setUnselectedBaggageWarning([], ticketId);
  };

  setTotalBaggageCost = (totalBaggageCost: number, ticketId: number) => {
    this.store.setTotalBaggageCost(totalBaggageCost, ticketId);
  };

  updateTotalBaggageCost = (ticketId: number) => {
    const newTotal = Object.keys(this.store.routes)
      .filter(key => key.startsWith(String(ticketId)))
      .reduce((sum, key) => sum + (this.store.routes[key].price || 0), 0);

    this.setTotalBaggageCost(newTotal, ticketId);
  };

  addBaggage = async () => {
    const requests = Object.entries(this.store.selectedBaggage).map(([ticketId, body]) =>
      this.api.addBaggageToCart(Number(ticketId), body),
    );

    await Promise.all(requests);
  };

  saveBaggageUpdateWarning = () => {
    const isAlreadyBaggageSelected = !!Object.values(this.store.selectedBaggage)
      .filter(({ BaggageRoutes }) => BaggageRoutes.some(route => route.Baggages.length > 0)).length;

    const keySelectedBaggage = Object.keys(this.store.selectedBaggage);

    localStorage.setItem('isAlreadyBaggageSelected', JSON.stringify(isAlreadyBaggageSelected));
    localStorage.setItem('keySelectedBaggage', JSON.stringify(keySelectedBaggage));
  };
}

export default AirlineBaggageService;
