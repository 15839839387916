import Api from '../../api';

import { featureFlagsStore } from './store';

import { MENU_KEYS } from '../../constants/chartsAnalytics';

import { FeatureFlagsProps } from './types';

class FeatureFlags {
  store = featureFlagsStore;
  api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  getFeatureFlags = (): FeatureFlagsProps => this.store.getFeatureFlags;

  loadFeatureFlags = () =>
    this.api.featureFlags
      .load()
      .then((featureFlags: FeatureFlagsProps) => this.store.setFeatureFlags(featureFlags));

  updateProjectsMandatory = (value: boolean) =>
    this.api.featureFlags.setMandatoryProjects(value);

  getSettingsFlags = async (accountId: number) => {
    try {
      const { CompaniesOfSummaryReviseAct } = await this.api.featureFlags.getSettingsFlags(accountId);
      this.store.setCompaniesOfSummaryReviseAct(CompaniesOfSummaryReviseAct);
    } catch (e) {
      this.store.setCompaniesOfSummaryReviseAct([]);
    }
  };

  getSearchServiceType = () =>
    this.getFeatureFlags().SearchServiceType || [];

  getSendSms = () => this.getFeatureFlags().SendSms || false;

  getDisableAirSeat = () =>
    this.getFeatureFlags().DisableAirSeat || false;

  getOnlyNewAnalytics = () =>
    this.getFeatureFlags().OnlyNewAnalytics || false;

  getDisableS7Balance = () =>
    this.getFeatureFlags().DisableS7Balance || false;

  getShowExpenseReports = () => this.getFeatureFlags().ShowExpenseReports || false;

  getExternalLink = () => this.getFeatureFlags().ExternalLink || false;

  getEmployeeNumSixDigits = () => this.getFeatureFlags().EmployeeNumSixDigits || false;

  getShowExpenseReportApprove = () => this.getFeatureFlags().ShowExpenseReportApprove || false;

  getMixvelWarning = () => this.getFeatureFlags().MixvelWarning || false;

  getShowVSKWarnText = () => this.getFeatureFlags().VSKWarnText || false;

  getBurCustomMessage = () => this.getFeatureFlags().BurCustomMessage || false;

  getTripRuleCustomMessage = () => this.getFeatureFlags().TripRuleCustomMessage || false;

  getBookingInMicroservice = () => this.getFeatureFlags().BookingInMicroservice || false;

  getPrefilledExpenseValues = () => this.getFeatureFlags().PrefilledExpenseValues || false;

  getReportGsprom = () => this.getFeatureFlags().ReportGsprom || false;

  getReportSevmash = () => this.getFeatureFlags().ReportSevmash || false;

  getFiltersHotelsInMicroservice = () => this.getFeatureFlags().FiltersHotelsInMicroservice || false;

  getUpsellFlags = () => this.getFeatureFlags().UpsellFlags || [];

  getDisplayInsurance = () => this.getFeatureFlags().DisplayInsurance || false;

  // FF для скрытого релиза редизайна Смарт Агента
  getShowElementForSmartagent = () => this.getFeatureFlags().ShowElementForSmartagent || false;

  // FF для скрытого релиза панели Баланс и панели Зарезервировано, до 1 спринта 4 PI
  getShowBalanceReservedSa = () => this.getFeatureFlags().ShowBalanceReservedSa || false;

  /** FF для скрытого релиза добавления дополнительного багажа для поставщика Mixvel */
  getShowAdditionalBaggageFlag = () => false;

  getNewAirlineSearch = () => this.getFeatureFlags().NewAirlineSearch || false;

  getPlateForGazpromCPS = () => this.getFeatureFlags().PlateForGazpromCPS || false;

  getReservedHotelSmartagent = () => this.getFeatureFlags().ReservedHotelSmartagent || false;

  getOpenMenuChartsAnalytics = () => {
    const menuSmartIndex = () => [
      MENU_KEYS.SMART.COMMON.FIRST,
      MENU_KEYS.SMART.AIRLINE.FIRST,
      MENU_KEYS.SMART.TRAIN.FIRST,
      MENU_KEYS.SMART.HOTEL.FIRST,
      MENU_KEYS.SMART.TAXI.FIRST,
      MENU_KEYS.SMART.AEROEXPRESS.FIRST,
      MENU_KEYS.SMART.TRANSFER.FIRST,
      MENU_KEYS.SMART.MICE.FIRST,
    ];

    return this.getFeatureFlags().MenuChartsAnalytics || [
      MENU_KEYS.GENERAL,
      ...menuSmartIndex(),
      MENU_KEYS.AIRLINE.SUMMARY,
      MENU_KEYS.AIRLINE.SUMMARY_NESTED,
      MENU_KEYS.AIRLINE.DIRECTIONS,
      MENU_KEYS.AIRLINE.DIRECTIONS_NESTED,
      MENU_KEYS.AIRLINE.COMPANIES,
      MENU_KEYS.AIRLINE.COMPANIES_NESTED,
      MENU_KEYS.AIRLINE.EARLINESS,
      MENU_KEYS.AIRLINE.EARLINESS_NESTED,
      MENU_KEYS.TRAIN.SUMMARY,
      MENU_KEYS.TRAIN.DIRECTIONS,
      MENU_KEYS.HOTEL.SUMMARY,
      MENU_KEYS.HOTEL.SUMMARY_NESTED,
      MENU_KEYS.HOTEL.DIRECTIONS,
      MENU_KEYS.HOTEL.DIRECTIONS_NESTED,
      MENU_KEYS.HOTEL.POPULAR,
      MENU_KEYS.HOTEL.POPULAR_NESTED,
      MENU_KEYS.TAXI.SUMMARY,
      MENU_KEYS.TAXI.SUMMARY_NESTED,
      MENU_KEYS.TAXI.DIRECTIONS,
      MENU_KEYS.TAXI.DIRECTIONS_NESTED,
      MENU_KEYS.AEROEXPRESS.SUMMARY,
      MENU_KEYS.AEROEXPRESS.SUMMARY_NESTED,
      MENU_KEYS.AEROEXPRESS.DIRECTIONS,
      MENU_KEYS.AEROEXPRESS.DIRECTIONS_NESTED,
      MENU_KEYS.TRANSFER.SUMMARY,
      MENU_KEYS.TRANSFER.SUMMARY_NESTED,
      MENU_KEYS.TRANSFER.DIRECTIONS,
      MENU_KEYS.TRANSFER.DIRECTIONS_NESTED,
      MENU_KEYS.MICE.SUMMARY,
      MENU_KEYS.MICE.SUMMARY_NESTED,
      MENU_KEYS.STRUCTURE.SERVICE_TYPE,
      MENU_KEYS.STRUCTURE.SERVICE_TYPE_NESTED,
      MENU_KEYS.STRUCTURE.DEPARTMENTS,
      MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED,
      MENU_KEYS.STRUCTURE.DEPARTMENTS_NESTED_SUB,
      MENU_KEYS.STRUCTURE.PROJECTS,
      MENU_KEYS.STRUCTURE.PROJECTS_NESTED,
      MENU_KEYS.STRUCTURE.PROJECTS_NESTED_SUB,
      MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS,
      MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS_NESTED,
      MENU_KEYS.STRUCTURE.CUSTOM_ANALYTICS_NESTED_SUB,
    ];
  };
}

export default FeatureFlags;
