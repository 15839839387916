import React, { Component, ReactNode } from 'react';
import { Select, Tag, Text, Button, Icon, IconButton, NoResults } from 'new-ui';
import { RouteComponentProps } from 'react-router-dom';
import { Moment } from 'moment';

import { getText } from '../../../../i18n';

import CONFIG from '../../../../config';

import FeatureFlags from '../../../bi/services/featureFlags';

import { HotelSearchMenu } from '../../../components/Menu/HotelSearchMenu';
import { YandexMaps } from '../../../components/YandexMap';
import { StickyPanel } from '../../../components/Sticky';
import { HotelsRegionResultSkeleton } from './HotelsRegionResultSkeleton/HotelsRegionResultSkeleton';
import { HotelResult } from './HotelsResult';
import { HotelsSearchFilter } from './Filters';
import { HotelProgress } from './HotelProgress';
import ContextAdvertising from '../../../components/ContextAdvertising';

import Hotel from '../../../../images/mice/context/hotel.webp';

import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';
import FAVORITESACTION from '../../../bi/constants/favorites';
import ROUTES from '../../../bi/constants/routes';
import {
  SEARCH_MENU_TYPES,
  VIEW_MODE_TYPES,
} from '../../../bi/services/hotels/constants/searchMenuTypes';
import { CONSTANTS } from '../../../bi/constants/yandexMap';
import { SELECTTRAVELLERS } from '../../../bi/constants/hotelsSearch';
import { NEW_SEARCH_SORT } from '../../../bi/constants/hotel';
import { POPUP_NAME } from '../../../bi/constants/popups';
import ACTION from '../../../bi/services/hotels/action';
import AppService from '../../../bi/services/app';

import MoneyFormat from '../../../bi/utils/money';
import scrollFastToTop from '../../utils/scrollToTop';
import { formatRangeDateWithSimplicity } from '../../../bi/utils/formatDate';
import { distanceToAreaMoreRegionRadius, getDistanceToArea } from '../../../bi/utils/gis';
import { isSmartAgent } from '../../../bi/utils/env';
import { MainAnalytic } from '../../../bi/utils/analytics';

import UserSessionService from '../../../bi/services/userSession';
import NotificationService from '../../../bi/services/notification';
import FavoriteService from '../../../bi/services/favorites';
import NewHotelsService from '../../../bi/services/newHotels';
import HotelsService from '../../../bi/services/hotels';
import {
  CustomRadius,
  Favorite,
  Filters,
  IHotelsStore,
  ITag,
  Paging,
  Placemarks,
  PreparedVariant,
  Region,
  RegionLocation,
  Variant,
  IDisplayFilters,
  IHotelsFilters,
} from '../../../bi/services/hotels/types';
import AccountSettings from '../../../bi/services/accountSettings';
import PopupsService from '../../../bi/services/popups';

import { ITravelPolicyListItem } from '../../../bi/types/travelPolicy';

import styles from './styles/hotels.module.css';

const DEFAULT_RADIUS = 0;
const WRAP_HEIGHT = 285;

const LABELS = {
  SEARCH: getText('hotels:regionResult.loader'),
  FAVORITE_NOT_FOUND_MESSAGE: (name: string, dates: string) => getText('hotels:regionResult.favoriteNotFound', { name, dates }),
  NOT_FOUND: getText('hotels:regionResult.notFound'),
  ANOTHER: getText('hotels:regionResult.another'),
  RESET_FILTERS: getText('hotels:regionResult.resetFilters'),
  CHANGE_PARAMS_OF_FILTERS: getText('hotels:regionResult.changeParamsOfFilters'),
  MINIMAL_COST: getText('hotels:regionResult.minimalCost'),
  NO_RESULTS_ONE: (region: string) => getText('hotels:regionResult.noResults.one', { region }),
  NO_RESULTS_TWO: getText('hotels:regionResult.noResults.two'),
  FAVORITE_REMOVE: (name: string) => getText('hotels:regionResult.notifications.favorites.remove', { name }),
  FAVORITE_ADD: (name: string) => getText('hotels:regionResult.notifications.favorites.add', { name }),
  LOADING_TITLE: getText('hotels:regionResult.loading.title'),
  LOADING_TEXT: getText('hotels:regionResult.loading.text'),
};

const STICKY_PANEL_ADJUSTMENT = 188;

const getPlacemarks = (cache: PreparedVariant[]): Placemarks[] => (cache?.length
  ? cache.map(
    ({ static: placemarksStatic, rate }) => ({
      Address: placemarksStatic.Address,
      Latitude: placemarksStatic.Latitude,
      Longitude: placemarksStatic.Longitude,
      IsSmartHotel: placemarksStatic.IsSmartHotel,
      Stars: placemarksStatic.Stars,
      Rating: placemarksStatic.Rating,
      MainImageUrl: placemarksStatic.Thumbnail,
      Name: placemarksStatic.HotelName,
      ClassificatorId: placemarksStatic.HotelId,
      rate: {
        Price: {
          TotalPrice: rate.Total,
          AgentFee: rate.AgentFee,
        },
      },
    }),
  )
  : []);

interface HotelResultWrapProps {
  hotelsService: HotelsService,
  newHotelsService: NewHotelsService,
  notificationService: NotificationService,
  favoriteService: FavoriteService,
  userSessionService: UserSessionService,
  history: RouteComponentProps['history'],
  travelPolicyList: ITravelPolicyListItem[],
  isFavoriteLastPath: boolean,
  generateLink: (hotelId: number, defaultLink: boolean) => string,
  onClickToActionLink(): void,
  onCopyToClipboard(): void,
  onCloseDialogCopyLink(): void,
  requestId?: number,
  aggregationId: number | null,
  requestItemId?: number,
  requestItemComment: string,
  onShowHotelOnMap(): void,
  onSwitchMap(item: { isMapView: boolean }): void,
  onSearchByRadius(): void,
  isFiltersHotelsInMicroservice: boolean,
  accountSettingsService: AccountSettings,
  popupsService: PopupsService,
  appService: AppService,
  isShownContext: boolean,
  onSetShownContext(value: boolean): void,
}

interface HotelResultWrapPropsState {
  items: PreparedVariant[],
  itemsMap: PreparedVariant[],
  checkin: string,
  checkout: string,
  customCheckout: Moment | null,
  customCheckin: Moment | null,
  adult: number,
  travellersCount: number,
  region: Region,
  sortBy: string,
  filters: Filters,
  paging: Paging,
  cache: PreparedVariant[],
  sources: Variant[],
  loadComplete: boolean,
  progress: number,
  hasOneSmart: boolean,
  hasOneGuaranteed: boolean,
  tags: ITag[],
  isAnyFavorite: boolean,
  unavailableTravelPolicy: boolean,
  favorite: Favorite,
  regionLocation: RegionLocation,
  viewMode: string,
  abortSearchByRadius: boolean,
  isMapView: boolean,
  mapHeight: number,
  travelPolicyList: ITravelPolicyListItem[],
  displayFilters: IDisplayFilters | null,
  hotelsFilters: IHotelsFilters,
  radiusCustom: boolean,
  setCacheOnMapToggler: boolean,
  initRadiusSearchHotels: boolean,
  initDoubleRadiusSearch: boolean,
  isRefund: boolean,
}

class HotelResultWrap extends Component<HotelResultWrapProps, HotelResultWrapPropsState> {
  static defaultProps = {
    requestId: 0,
    requestItemId: 0,
  };

  pageHeaderRef: HTMLDivElement | null = null;
  isFavoriteLastPath: boolean;
  unsubscribeFn: () => void;

  constructor(props: HotelResultWrapProps) {
    super(props);

    const {
      items,
      sortBy,
      filters,
      paging,
      cache,
      itemsMap,
      sources,
      loadComplete,
      progress,
      hasOneSmart,
      hasOneGuaranteed,
      tags,
      isAnyFavorite,
      unavailableTravelPolicy,
      favorite,
      regionLocation,
      viewMode,
      abortSearchByRadius,
      displayFilters,
      initDoubleRadiusSearch,
      hotelsFilters,
      radiusCustom,
      setCacheOnMapToggler,
      initRadiusSearchHotels,
      isRefund,
    } = props.hotelsService.getHotelsState();

    const {
      checkin,
      checkout,
      adult,
      region,
      customCheckin,
      customCheckout,
      travellersCount,
    } = props.hotelsService.getSearchState();

    this.state = {
      items,
      itemsMap,
      checkin,
      checkout,
      customCheckout,
      customCheckin,
      adult,
      travellersCount,
      region,
      sortBy,
      filters,
      paging,
      cache,
      sources,
      loadComplete,
      progress,
      hasOneSmart,
      hasOneGuaranteed,
      tags,
      isAnyFavorite,
      unavailableTravelPolicy,
      favorite,
      regionLocation,
      viewMode,
      abortSearchByRadius,
      displayFilters,
      hotelsFilters,
      radiusCustom,
      setCacheOnMapToggler,
      initRadiusSearchHotels,
      initDoubleRadiusSearch,
      isRefund,
      isMapView: !this.props.isFiltersHotelsInMicroservice,
      mapHeight: 0,
      travelPolicyList: props.userSessionService.get().travelPolicies,
    };

    this.isFavoriteLastPath = props.isFavoriteLastPath;
  }

  componentDidMount() {
    const {
      hotelsService: {
        subscribeHotels,
        setViewMode,
      },
      isFiltersHotelsInMicroservice,
    } = this.props;
    const { viewMode } = this.state.filters.changed;

    if (isFiltersHotelsInMicroservice && !viewMode) {
      setViewMode(ACTION.UPDATEVIEWMODE, VIEW_MODE_TYPES.LIST, true);
    }

    this.updateMapDimensions();
    window.addEventListener('resize', this.updateMapDimensions);

    this.unsubscribeFn = subscribeHotels(this.updateState);
  }

  componentWillUnmount() {
    if (this.unsubscribeFn) this.unsubscribeFn();

    window.removeEventListener('resize', this.updateMapDimensions);
  }

  updateState = (state: IHotelsStore) => {
    this.setState(
      {
        items: state.items,
        cache: state.cache,
        itemsMap: state.itemsMap,
        sources: state.sources,
        paging: state.paging,
        sortBy: state.sortBy,
        loadComplete: state.loadComplete,
        progress: state.progress,
        hasOneSmart: state.hasOneSmart,
        hasOneGuaranteed: state.hasOneGuaranteed,
        tags: state.tags,
        filters: state.filters,
        isAnyFavorite: state.isAnyFavorite,
        unavailableTravelPolicy: state.unavailableTravelPolicy,
        regionLocation: state.regionLocation,
        abortSearchByRadius: state.abortSearchByRadius,
        viewMode: state.viewMode,
        displayFilters: state.displayFilters,
        hotelsFilters: state.hotelsFilters,
        radiusCustom: state.radiusCustom,
        setCacheOnMapToggler: state.setCacheOnMapToggler,
        initRadiusSearchHotels: state.initRadiusSearchHotels,
        initDoubleRadiusSearch: state.initDoubleRadiusSearch,
        isRefund: state.isRefund,
      },
    );
  };

  updateMapDimensions = () => {
    this.setState({ mapHeight: window.innerHeight });
  };

  handleUpdateFilters = (field: string, value: CustomRadius) => {
    const { isFiltersHotelsInMicroservice } = this.props;

    this.props.hotelsService.setHotels(field, value, isFiltersHotelsInMicroservice);
  };

  handleSortSearchResult = (value: number | string) => {
    const {
      isFiltersHotelsInMicroservice,
      hotelsService: {
        setHotels,
        getSortValue,
      },
    } = this.props;

    setHotels('sortBy', value, isFiltersHotelsInMicroservice);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_HOTEL_SORTING_CHOSEN(
        getSortValue().find(i => i.value === value)?.analyticValue,
      ),
    );
  };

  setFilterAfterSwitch = (isMapView: boolean, radiusValue: number, customRadiusValue: boolean) => {
    const {
      hotelsService: {
        setHotels,
        updateFilterRadius,
      },
      isFiltersHotelsInMicroservice,
    } = this.props;
    const {
      initRadiusSearchHotels,
      regionLocation: { latitude, longitude },
    } = this.state;

    if (!isFiltersHotelsInMicroservice || !isMapView) return null;

    setHotels(ACTION.UPDATECOORDSPOINT, [latitude, longitude], isFiltersHotelsInMicroservice);

    if (initRadiusSearchHotels) {
      return updateFilterRadius({
        value: {
          value: radiusValue,
          custom: customRadiusValue,
        },
        isFiltersHotelsInMicroservice,
      });
    }

    if (!initRadiusSearchHotels && radiusValue) {
      this.handleUpdateFilters(ACTION.UPDATE_RADIUS_FILTER, {
        value: radiusValue,
        custom: false,
      });
    }

    return null;
  };

  handleSwitchView = (viewMode: VIEW_MODE_TYPES) => {
    const {
      hotelsService: {
        setViewMode,
      },
      onSwitchMap,
      isFiltersHotelsInMicroservice,
    } = this.props;
    const { radius } = this.state.filters;
    const {
      radiusCustom,
      hotelsFilters: {
        Hotel: {
          Proximity: {
            Radius,
          },
        },
      },
    } = this.state;

    const isMapView = viewMode === VIEW_MODE_TYPES.LIST_MAP;
    const customRadiusValue = isFiltersHotelsInMicroservice ? radiusCustom : radius.custom;
    const radiusValue = isFiltersHotelsInMicroservice ? Radius : radius.value;

    this.setFilterAfterSwitch(isMapView, radiusValue, customRadiusValue);

    if (customRadiusValue && !isMapView) {
      this.handleUpdateFilters(ACTION.UPDATE_RADIUS_FILTER, {
        value: radiusValue,
        custom: false,
      });
    }

    this.setState({ isMapView },
      () => onSwitchMap({ isMapView }),
    );

    setViewMode(ACTION.UPDATEVIEWMODE, viewMode);

    scrollFastToTop();
  };

  handleResetFilters = () => {
    const { isFiltersHotelsInMicroservice } = this.props;
    const {
      hotelsFilters: { Hotel: { Proximity: { Radius } } },
    } = this.state;

    this.isFavoriteLastPath = false;
    this.props.hotelsService.resetFilters(isFiltersHotelsInMicroservice, Radius);
  };

  handleChangeFavorite = (hotel: PreparedVariant, action: string) => {
    if (action === FAVORITESACTION.REMOVE) {
      const { favoriteService, hotelsService, notificationService } = this.props;

      return favoriteService.removeItem(hotel.favoriteId).then(() => {
        notificationService.send({
          message: LABELS.FAVORITE_REMOVE(hotel.static.HotelName),
          // @ts-ignore
          level: 'success',
          qaAttr: QA_ATTRIBUTES.hotels.result.notificationRemoveFavorite,
        });

        hotelsService.changeFavoriteStatus(hotel.static.HotelId, '');
      });
    }

    const { hotelsService, notificationService, history } = this.props;

    const {
      region: {
        selected: { Id: regionId },
      },
    } = this.props.hotelsService.getSearchState();

    const preparedHotel = {
      Address: hotel.static.Address,
      Amenities: {
        ...hotel.static.Amenities,
      },
      ClassificatorId: hotel.static.HotelId,
      DistanceFromCenter: hotel.static.DistanceFromCenter,
      IsSmartHotel: hotel.static.IsSmartHotel,
      Latitude: hotel.static.Latitude,
      Longitude: hotel.static.Longitude,
      MainImageUrl: hotel.static.Thumbnail,
      Name: hotel.static.HotelName,
      RegionId: regionId,
      Stars: hotel.static.Stars,
      Type: hotel.static.Type,
    };

    return hotelsService.addToFavorite(preparedHotel).then((favoriteId: string) => {
      notificationService.send({
        message: LABELS.FAVORITE_ADD(hotel.static.HotelName),
        // @ts-ignore
        level: 'success',
        onClick: () => history.push(ROUTES.FAVORITES),
        qaAttr: QA_ATTRIBUTES.hotels.result.notificationFavorite,
      });

      const id = favoriteId.split('"').join('');

      hotelsService.changeFavoriteStatus(hotel.static.HotelId, id);
    });
  };

  handleTogglePoint = (coords = []) => {
    const {
      isFiltersHotelsInMicroservice,
      hotelsService: {
        setHotels,
      },
    } = this.props;
    const { radius } = this.state.filters;
    const {
      hotelsFilters: {
        Hotel: {
          Proximity: {
            Radius,
          },
        },
      },
    } = this.state;

    const custom = !!coords.length;
    const radiusValue = isFiltersHotelsInMicroservice ? Radius : radius.value;

    setHotels(ACTION.UPDATECOORDSPOINT, coords, isFiltersHotelsInMicroservice);

    if (coords.length && !isFiltersHotelsInMicroservice) {
      setHotels(ACTION.UPDATE_DISTANCE_FROM_POINT, coords, isFiltersHotelsInMicroservice);
    }

    return this.handleUpdateFilters(ACTION.UPDATE_RADIUS_FILTER, {
      value: custom && !radiusValue ? DEFAULT_RADIUS : radiusValue,
      custom,
    });
  };

  handleToggleSelection = (coords = []) => {
    const { hotelsService, onSearchByRadius, isFiltersHotelsInMicroservice } = this.props;
    const {
      filters: {
        radius: {
          value,
        },
      },
      hotelsFilters: {
        Hotel: {
          Proximity: {
            Radius,
          },
        },
      },
      regionLocation: { latitude, longitude, radius },
      abortSearchByRadius,
      isRefund,
    } = this.state;

    const radiusValue = isFiltersHotelsInMicroservice ? Radius : value;
    const distance = getDistanceToArea([latitude, longitude], coords, radiusValue);
    const abort = distanceToAreaMoreRegionRadius(distance, radius);

    if (isFiltersHotelsInMicroservice) {
      hotelsService.setHotels(ACTION.UPDATECOORDSPOINT, coords, isFiltersHotelsInMicroservice);

      // после первого запуска, каждый раз, когда ищем с радиусом на карте идет запуск нового поиска
      if (coords?.length && abortSearchByRadius && !isRefund) {
        onSearchByRadius();

        return;
      }
    }

    if (coords?.length && abort && !isRefund) {
      onSearchByRadius();

      return;
    }

    if ((abort || abortSearchByRadius) && !isFiltersHotelsInMicroservice) {
      hotelsService.abortSearchByRadius();
    }
  };

  resetRadius = () => {
    const { isFiltersHotelsInMicroservice } = this.props;
    const {
      filters: {
        radius: {
          custom,
        },
      },
      radiusCustom,
    } = this.state;

    const customRadiusValue = isFiltersHotelsInMicroservice ? radiusCustom : custom;

    this.handleUpdateFilters(ACTION.UPDATE_RADIUS_FILTER, {
      value: DEFAULT_RADIUS,
      custom: customRadiusValue,
    });
  };

  handlePageChange = (page: number) => {
    const { isFiltersHotelsInMicroservice } = this.props;

    if (this.pageHeaderRef) {
      setTimeout(
        () =>
          window.scroll({
            top: 0,
            behavior: 'smooth',
          }),
        100,
      );
    }

    this.props.hotelsService.setHotels('paging', page, isFiltersHotelsInMicroservice);
  };

  handleGetLinkAction = (item: PreparedVariant, linkHotel: string) => {
    const {
      checkin: dateFrom,
      checkout: dateTo,
    } = this.props.hotelsService.getSearchState();

    const link = window.location.origin + linkHotel;
    const rateTotal = item.rate ? item.rate.Total : 0;

    const description = `${item.static.HotelName}, ${dateFrom.format('DD/MM')} - ${dateTo.format(
      'DD/MM',
    )}, ${LABELS.MINIMAL_COST} ${MoneyFormat.money(rateTotal, true)}`;

    return `${description} \n\n${link}`;
  };

  updateVisibleHotel = (ids: number[], value = true) => {
    const { isFiltersHotelsInMicroservice } = this.props;

    this.props.hotelsService.setHotels(
      ACTION.UPDATE_VISIBLE_HOTEL_ON_MAP,
      {
        value,
        visibleHotels: ids,
      },
      isFiltersHotelsInMicroservice,
    );
  };

  renderSortItem = ({ label, value }: { label: string, value: number | string }) => {
    const { isFiltersHotelsInMicroservice } = this.props;

    const items = isFiltersHotelsInMicroservice
      ? NEW_SEARCH_SORT
      : this.props.hotelsService.getSortValue();
    const currentItem = items.find((item) => item.value === value);

    const labelContent = (
      <Text type='NORMAL_16' color='gray' >
        {label}
      </Text>
    );

    if (currentItem?.type !== null) {
      return (
        <div className={ styles['dropdown-item'] }>
          {labelContent}
          <Icon
            className={ styles.icon }
            type={ currentItem?.type === 1 ? 'arrowsUp' : 'arrowsDown' }
          />
        </div>
      );
    }

    return labelContent;
  };

  renderFilterEmptyPanel = () => {
    const { favorite } = this.state;
    const title =
      this.isFavoriteLastPath && favorite
        ? LABELS.FAVORITE_NOT_FOUND_MESSAGE(favorite.name, formatRangeDateWithSimplicity(favorite.dates.from, favorite.dates.to))
        : LABELS.NOT_FOUND;
    const buttonLabel = this.isFavoriteLastPath ? LABELS.ANOTHER : LABELS.RESET_FILTERS;

    const renderContent = () => (
      <div className={ styles['not-found-content'] }>
        <Text type='bold_18' color='gray' className={ styles.title }>{title}</Text>
        <Text type='NORMAL_14' color='gray' className={ styles.hint }>
          { LABELS.CHANGE_PARAMS_OF_FILTERS }
        </Text>
        <Button type='primary-outline' onClick={ this.handleResetFilters } className={ styles.button }>
          { buttonLabel }
        </Button>
      </div>
    );

    return <NoResults renderContent={ renderContent } styles={ { marginTop: '36px' } } />;
  };

  renderEmptyFilter = () => (
    <div className={ styles.result }>
      <div className={ `${styles.items} ${styles.empty}` }>
        {this.renderFilterEmptyPanel()}
      </div>
    </div>
  );

  renderNoResults = () => {
    const { region: { label } } = this.props.hotelsService.getSearchState();

    const renderContent = () => (
      <>
        <Text type='bold_16' color='gray' className={ styles.text }>
          { LABELS.NO_RESULTS_ONE(label) }
        </Text>
        <Text type='bold_16' color='gray' className={ styles.text }>
          { LABELS.NO_RESULTS_TWO }
        </Text>
      </>
    );

    return (
      <div className={ styles.result }>
        <div className={ `${styles.items} ${styles.empty}` }>
          <NoResults renderContent={ renderContent } />
        </div>
      </div>
    );
  };

  renderFullPage = (html: ReactNode, itemsWrapClass = '') => {
    const {
      filters,
      hasOneGuaranteed,
      hasOneSmart,
      isAnyFavorite,
      travelPolicyList,
      unavailableTravelPolicy,
      displayFilters,
      hotelsFilters,
      radiusCustom,
      region,
    } = this.state;
    const {
      hotelsService,
      requestId,
      aggregationId,
      isFiltersHotelsInMicroservice,
    } = this.props;

    return (
      <div className={ styles.result }>
        <HotelsSearchFilter
          filters={ filters }
          hasOneSmart={ hasOneSmart }
          hasOneGuaranteed={ hasOneGuaranteed }
          unavailableTravelPolicy={ unavailableTravelPolicy }
          travelPolicyList={ travelPolicyList }
          updateFilters={ this.handleUpdateFilters }
          amenitiesRu={ hotelsService.getAmenities('ru') }
          typeRu={ hotelsService.getType('ru') }
          isAnyFavorite={ isAnyFavorite }
          requestId={ requestId }
          aggregationId={ aggregationId }
          isFiltersHotelsInMicroservice={ isFiltersHotelsInMicroservice }
          displayFilters={ displayFilters }
          hotelsFilters={ hotelsFilters }
          radiusCustom={ radiusCustom }
          region={ region }
        />
        <div className={ `${styles.items} ${itemsWrapClass}` }>
          { this.renderHeader() }
          { html }
        </div>
      </div>
    );
  };

  getColorForPins = (key: string) => {
    const { viewMode } = this.state;

    if (
      (viewMode === VIEW_MODE_TYPES.LIST || viewMode === VIEW_MODE_TYPES.LIST_MAP)
      && viewMode === key
    ) {
      return isSmartAgent ? 'green' : 'red';
    }

    return 'gray';
  };

  goToMice = () => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MICE.CONTEXT.HOTELS_REGION.PRESSED);

    window.open(ROUTES.EVENT_CONTEXT, '_blank');
  };

  renderHeaderContent = () => {
    const { hotelsService, isFiltersHotelsInMicroservice } = this.props;

    const {
      sortBy,
      loadComplete,
      tags,
      sources,
      itemsMap,
      hotelsFilters: {
        SortType,
      },
      viewMode,
    } = this.state;

    const items = isFiltersHotelsInMicroservice
      ? NEW_SEARCH_SORT
      : hotelsService.getSortValue().map(({ label, value }) => ({ label, value }));
    const sortValue = isFiltersHotelsInMicroservice ? SortType : sortBy;
    const countHotelsLength = isFiltersHotelsInMicroservice && VIEW_MODE_TYPES.LIST_MAP === viewMode
      ? itemsMap.length
      : sources.length;

    const sortContent = (
      <div className={ styles.select }>
        <Select
          qaAttr={ QA_ATTRIBUTES.hotels.result.suggest }
          renderItem={ this.renderSortItem }
          renderLabel={ this.renderSortItem }
          items={ items }
          value={ sortValue }
          theme='default-small'
          onChange={ this.handleSortSearchResult }
        />
      </div>
    );

    const tagsHtml =
      !!tags.length &&
      tags.map((tag) => {
        const { key, name } = tag;
        const qaAttr = `${QA_ATTRIBUTES.hotels.search.tag}-${key}`;

        return (
          <Tag
            className={ styles.tag }
            label={ name }
            key={ key }
            onClick={ () => hotelsService.deleteTag(tag, isFiltersHotelsInMicroservice) }
            qaAttr={ qaAttr }
          />
        );
      });

    return (
      <div className={ styles['loading-map-btn-wrapper'] }>
        <HotelProgress
          count={ countHotelsLength }
          loading={ !loadComplete }
        />
        <div className={ styles.tags }>{tagsHtml}</div>
        <div className={ styles['filters-and-modes-wrapper'] }>
          <div className={ styles.filters }>{sortContent}</div>
          <div className={ styles['modes-wrapper'] }>
            <IconButton
              qaAttr={ QA_ATTRIBUTES.hotels.result.region.buttonList }
              className={ styles['modes-button'] }
              iconType='list'
              iconColor={ this.getColorForPins(VIEW_MODE_TYPES.LIST) }
              onClick={ () => this.handleSwitchView(VIEW_MODE_TYPES.LIST) }
            />
            <IconButton
              className={ styles['modes-button'] }
              iconType='pinsMap'
              iconColor={ this.getColorForPins(VIEW_MODE_TYPES.LIST_MAP) }
              onClick={ () => this.handleSwitchView(VIEW_MODE_TYPES.LIST_MAP) }
            />
          </div>
        </div>
      </div>
    );
  };

  renderHeader = () => {
    const { requestItemComment } = this.props;

    const requestCommentHtml = requestItemComment && (
      <Text type='NORMAL_16' className={ styles.comment } >{requestItemComment}</Text>
    );

    return (
      <div className={ styles.header } ref={ (ref) => { this.pageHeaderRef = ref; } }>
        <StickyPanel
          adjustment={ STICKY_PANEL_ADJUSTMENT }
          fixedClass={ styles['sticky-panel-fixed'] }
          className={ styles['sticky-panel'] }
        >
          <div className={ styles.main }>
            {requestCommentHtml}
            {this.renderHeaderContent()}
          </div>
        </StickyPanel>
      </div>
    );
  };

  renderListView = (nightsCount: string) => {
    const {
      hotelsService,
      newHotelsService,
      travelPolicyList,
      onClickToActionLink,
      onCopyToClipboard,
      onCloseDialogCopyLink,
      onShowHotelOnMap,
      generateLink,
      requestItemId,
      aggregationId,
      isFiltersHotelsInMicroservice,
      appService,
    } = this.props;

    const {
      checkin,
      checkout,
      customCheckin,
      customCheckout,
      adult,
      travellersCount,
      region,
      items,
      paging,
      filters,
      itemsMap,
      viewMode,
      sources,
      loadComplete,
      hotelsFilters,
      hotelsFilters: {
        Rate: {
          TravelPolicy,
        },
      },
      displayFilters,
      initDoubleRadiusSearch,
    } = this.state;

    const selectTravel = SELECTTRAVELLERS[travellersCount].find(item => item.value === adult);
    const roomCount = selectTravel ? selectTravel.roomCount : 1;
    const selectedTP = isFiltersHotelsInMicroservice ? TravelPolicy : filters.selectedTravelPolicy;
    const isEpmtyHotels = isFiltersHotelsInMicroservice && !sources.length;

    // проверка для новой выдачи
    if (isEpmtyHotels && !displayFilters?.CountHotels && loadComplete && initDoubleRadiusSearch) {
      return this.renderNoResults();
    }

    if (isEpmtyHotels && (displayFilters?.CountHotels || loadComplete)) {
      return this.renderEmptyFilter();
    }

    // для старой выдачи
    if (!sources.length && loadComplete) {
      return this.renderNoResults();
    }

    if (sources.length && !items.length && viewMode !== VIEW_MODE_TYPES.LIST_MAP) {
      return this.renderEmptyFilter();
    }

    if (sources.length && !itemsMap.length) {
      return this.renderEmptyFilter();
    }

    return (
      <div className={ styles.list } data-qa={ QA_ATTRIBUTES.hotels.result.region.list }>
        { this.renderContextAdvertising() }
        <HotelResult
          hotelsFilters={ hotelsFilters }
          filters={ filters }
          hotelsService={ hotelsService }
          newHotelsService={ newHotelsService }
          appService={ appService }
          hotels={ items }
          travelPolicyList={ travelPolicyList }
          paging={ paging }
          query={ {
            checkin,
            checkout,
            adult,
            region,
            customCheckout,
            customCheckin,
            requestItemId,
            travellersCount,
            roomCount,
          } }
          showPriceDetails={ hotelsService.isShowPriceDetails() }
          selectedTravelPolicy={ selectedTP }
          handlePageChange={ this.handlePageChange }
          onChangeFavorite={ this.handleChangeFavorite }
          onGetLink={ this.handleGetLinkAction }
          onClickToActionLink={ onClickToActionLink }
          onCopyToClipboard={ onCopyToClipboard }
          onCloseDialogCopyLink={ onCloseDialogCopyLink }
          onShowHotelOnMap={ onShowHotelOnMap }
          nightsCount={ nightsCount }
          aggregationId={ aggregationId }
          generateLink={ generateLink }
          isFiltersHotelsInMicroservice={ isFiltersHotelsInMicroservice }
        />
      </div>
    );
  };

  renderListMapView = (nightsCount: string) => (
    <div className={ styles['list-map-wrap'] }>
      {this.renderListView(nightsCount)}
      {this.renderMapView(nightsCount)}
    </div>
  );

  renderMapView = (nightsCount: string) => {
    const { generateLink, isFiltersHotelsInMicroservice, appService } = this.props;
    const {
      progress,
      filters: { radius },
      regionLocation: { latitude, longitude },
      mapHeight,
      itemsMap,
      cache,
      hotelsFilters: {
        Hotel: {
          Proximity: {
            Radius,
          },
        },
      },
      setCacheOnMapToggler,
    } = this.state;
    const { agentMode } = appService.get();

    const radiusValue = isFiltersHotelsInMicroservice ? Radius : radius.value;
    const placemarks: Placemarks[] = getPlacemarks(cache);
    const center = [latitude, longitude];
    const wrapHeight = mapHeight - WRAP_HEIGHT;
    const selectionRadius = radiusValue || DEFAULT_RADIUS;
    const itemsMapIds: number[] = itemsMap.map(({ static: { HotelId } }) => HotelId);

    return (
      <div className={ styles['map-wrap'] } style={ { height: `${wrapHeight}px` } }>
        <YandexMaps
          setCacheOnMapToggler={ setCacheOnMapToggler }
          withSelection
          selectionRadius={ selectionRadius }
          center={ center }
          zoom={ CONSTANTS.ZOOM }
          itemsMapIds={ itemsMapIds }
          placeMarks={ placemarks }
          generateLink={ generateLink }
          progress={ progress }
          customPlacemarks
          APIKEY={ CONFIG.YANDEX_MAPS_API_KEY }
          nightsCount={ nightsCount }
          onTogglePoint={ this.handleTogglePoint }
          onFormatMoney={ MoneyFormat.money }
          onToggleSelection={ this.handleToggleSelection }
          onResetRadius={ this.resetRadius }
          onUpdateVisibleHotel={ this.updateVisibleHotel }
          agentMode={ agentMode }
        />
      </div>
    );
  };

  renderContextAdvertising = () => {
    const {
      aggregationId,
      accountSettingsService: {
        getNoMice,
      },
      popupsService: {
        closePopupState,
        store: {
          popupsState: {
            mice_hotels_region_group_search,
          },
        },
      },
      onSetShownContext,
      isShownContext,
    } = this.props;
    const { travellersCount, isMapView } = this.state;

    if (isMapView
      || travellersCount < 4
      || aggregationId
      || getNoMice()
      || !isShownContext
      || isSmartAgent
    ) {
      return null;
    }

    if (!mice_hotels_region_group_search) {
      closePopupState(POPUP_NAME.CONTEXT.HOTEL_REGION);
    }

    return (
      <ContextAdvertising
        image={ Hotel }
        onClick={ this.goToMice }
        onClose={ () => onSetShownContext(false) }
        classnameBtn={ styles.wrapper_context }
        amplitude={ MainAnalytic.ACTIONS.MICE.CONTEXT.HOTELS_REGION.SHOWED }
      />
    );
  };

  render() {
    const { hotelsService } = this.props;
    const { isMapView } = this.state;

    const nightsCount = hotelsService.getSearchDatesDiff();

    const html = isMapView
      ? this.renderListMapView(nightsCount)
      : this.renderListView(nightsCount);

    const additionalStyles = isMapView ? styles.items_wrap_map : '';

    return this.renderFullPage(html, additionalStyles);
  }
}

interface HotelsResultProps {
  hotelsService: HotelsService,
  newHotelsService: NewHotelsService,
  notificationService: NotificationService,
  history: RouteComponentProps['history'],
  favoriteService: FavoriteService,
  userSessionService: UserSessionService,
  appService: AppService,
  travelPolicyList: any[],
  loading: boolean,
  onSearchSubMenu(): void,
  onSearchByRadius(): void,
  generateLink: (hotelId: number, defaultLink: boolean) => string,
  onClickToActionLink(): void,
  onCopyToClipboard(): void,
  onCloseDialogCopyLink(): void,
  isFavoriteLastPath: boolean,
  onShowHotelOnMap(): void,
  requestId: number,
  aggregationId: number | null,
  requestItemId: number,
  requestItemComment: string,
  onSwitchMap?(item: { isMapView: boolean }): void,
  isFiltersHotelsInMicroservice: boolean,
  accountSettingsService: AccountSettings,
  popupsService: PopupsService,
  isShownContext: boolean,
  onSetShownContext(value: boolean): void,
  featureFlagsService: FeatureFlags,
}

const HotelsResult = ({
  loading,
  hotelsService,
  newHotelsService,
  userSessionService,
  onSearchSubMenu,
  onSearchByRadius,
  generateLink,
  notificationService,
  history,
  favoriteService,
  travelPolicyList,
  isFavoriteLastPath,
  requestId = 0,
  aggregationId,
  requestItemId = 0,
  requestItemComment = '',
  onSwitchMap = () => {},
  onClickToActionLink,
  onCopyToClipboard,
  onCloseDialogCopyLink,
  onShowHotelOnMap,
  isFiltersHotelsInMicroservice,
  accountSettingsService,
  popupsService,
  appService,
  isShownContext,
  onSetShownContext,
  featureFlagsService,
}: HotelsResultProps) => {
  const html = loading
    ? <HotelsRegionResultSkeleton />
    : <HotelResultWrap
        hotelsService={ hotelsService }
        newHotelsService={ newHotelsService }
        notificationService={ notificationService }
        generateLink={ generateLink }
        history={ history }
        favoriteService={ favoriteService }
        appService={ appService }
        userSessionService={ userSessionService }
        travelPolicyList={ travelPolicyList }
        isFavoriteLastPath={ isFavoriteLastPath }
        requestId={ requestId }
        aggregationId={ aggregationId }
        requestItemId={ requestItemId }
        requestItemComment={ requestItemComment }
        onSwitchMap={ onSwitchMap }
        onClickToActionLink={ onClickToActionLink }
        onCopyToClipboard={ onCopyToClipboard }
        onCloseDialogCopyLink={ onCloseDialogCopyLink }
        onShowHotelOnMap={ onShowHotelOnMap }
        onSearchByRadius={ onSearchByRadius }
        isFiltersHotelsInMicroservice={ isFiltersHotelsInMicroservice }
        accountSettingsService={ accountSettingsService }
        popupsService={ popupsService }
        isShownContext={ isShownContext }
        onSetShownContext={ onSetShownContext }
    />;

  return (
    <>
      <HotelSearchMenu
        hotelsService={ hotelsService }
        type={ SEARCH_MENU_TYPES.SUB_MENU }
        onSearch={ () => {} }
        onSearchSubMenu={ onSearchSubMenu }
        onChangeForm={ onSearchSubMenu }
        featureFlagsService={ featureFlagsService }
      />
      { html }
    </>
  );
};

export default HotelsResult;
